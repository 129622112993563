import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

/**
 * Provides the most recent user interaction event type given a whitelist of events.
 * @param {object} props
 * @param {Function} props.children - The render prop function
 * @param {Array} props.events - The events to listen for
 */
export function UserInteractionTracker({ children, events }) {
  const [lastInteraction, setLastInteraction] = useState(null);

  useEffect(() => {
    const handlers = {};

    events.forEach((event) => {
      /** @returns {void} */
      handlers[event] = () => setLastInteraction(event);
      document.addEventListener(event, handlers[event]);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handlers[event]);
      });
    };
  }, [events]);

  return children(lastInteraction);
}

UserInteractionTracker.propTypes = {
  children: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(PropTypes.string).isRequired,
};
